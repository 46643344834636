import React from "react"
import Video from "../atoms/Video"

const VideoFigure = ({
  className,
  caption,
  _uid = "7",
  video_url,
  ratio = "16by9",
}) => {
  return (
    <figure className={className}>
      <div>
        <Video video_url={video_url} ratio={ratio} _uid={_uid} />
      </div>
      {caption && (
        <div className={`-mx-grid`}>
          <figcaption className="w-full max-w-screen-lg mx-auto mt-4 px-grid">
            {caption}
          </figcaption>
        </div>
      )}
    </figure>
  )
}

export default VideoFigure
